import React, { useEffect, useState, useContext } from "react";
import { Card, Row } from "react-bootstrap";
import Graph from "./Graph";
import SearchBar, { SearchContext } from "./SearchBar";

const ThesaurusExplorer = () => {
  const { searchQuery, searchAPI, setSearchAPI } = useContext(SearchContext);
  const [rootNode, setRootNode] = useState({ title: searchQuery, count: 0, children: [] });

  useEffect(() => {
    if (searchQuery !== "") {
      fetchLocData(searchQuery);
    }
  }, [searchQuery]);

  const fetchLocData = (searchQuery) => {
    const baseURL = "https://www.loc.gov/search/?fo=json&q=";
    fetch(baseURL + searchQuery)
      .then((response) => response.json())
      .then((data) => {
        const facets = data.facets;
        console.log("facets data is ", facets);
        // Extract related nodes from data and update state
        const relatedNodes = extractRelatedNodes(facets);
        let totalCount = 0; // Calculate the total count
        for (let node of relatedNodes) {
          totalCount += node.count;
        }
        setRootNode({ title: searchQuery, count: totalCount, children: relatedNodes });
      });
  };

// for 1st set of nodes
const extractRelatedNodes = (facets) => {
  const nodes = [];
  for (let facet of facets) {
    console.log("facet main one " + JSON.stringify(facet));
    let total = 0;
    for (let filter of facet.filters) {
      if ('count' in filter) {
        total += filter.count;
      }
    }
    console.log("Total count of this facet: " + total + " with title: " + facet.title);
    const parentNode = { // Create the parent node
      title: facet.title, 
      count: total, // the count of the parent node is the total count of all filters in this facet
      filters: facet.filters, 
      children: [] 
    };
    for (let filter of facet.filters) { // Add children to the parent node
        let childNode = {
            title: filter.title,
            count: filter.count,
            filters: [],
            children: []
        };
        parentNode.children.push(childNode);
    }
    nodes.push(parentNode); // Add the parent node to the array
  }
  return nodes;
  }
  
  // for edge nodes after the 1st set
  const extractEdgeNodes = (filters) => {
  const nodes = [];
  for (let filter of filters) {
    console.log("filter main one " + JSON.stringify(filter));
    const node = {
      title: filter.title, 
      count: filter.count, 
      filters: [], 
      children: [] 
    };
    nodes.push(node); // Add the node to the array
  }
  return nodes;
  }
  
  // facet node
  // https://www.loc.gov/search/?in=&q=audrey+munson&new=true
  /// edge node (Location -> washington)
  // https://www.loc.gov/search/?fa=location:washington&q=audrey+munson
  // edg node + 1
  // https://www.loc.gov/search/?fa=location:washington%7Clocation:maryland&q=audrey+munson
  
  // use logic to play with facet nodes and edge nodes later. See above links. Try filtering by the
  // 2nd ":"
  const fetchAndSetRelatedNodes = (url, node) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("this data in fetch/set is " + JSON.stringify(data));
        const newNodes = extractRelatedNodes(data.facets);
        node.children = newNodes;
        setRootNode({...rootNode}); // re-render the component with the updated tree
      });
  };

  return (
    <React.Fragment>
      <Graph
        rootNode={rootNode}
        onNodeClick={fetchAndSetRelatedNodes}
        keyword={searchQuery}
      />
    </React.Fragment>
  );
}

export default ThesaurusExplorer;
