import axios from "axios";
import { store } from "../redux/store";

// should change this to "getAppUrl" when ready. base is confusing
const getBaseUrl = () => {
  return process.env.REACT_APP_SERVER_URL;
};

const getLOCSuggestionsUrl = () => {
  return process.env.REACT_APP_NATIONAL_ARCHIVE_SEARCH_BASE_URL;
};

/**
 * todo update calls to use state instead of passing tokens around in arguments
 * confirmed store is working as expected
 * NOTE: response.data should be used when calling this.... for now.
 */
const getDataSecure = (url, token, params) => {
  const state = store.getState();
  if (token == null) {
    token = state.Auth.token;
  }
  const options = {
    params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const apiUrl = getBaseUrl() + url;
  //NOTE response is being passed here.... not response.data
  return axios
    .get(apiUrl, options)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.pathname = "/auth/signout";
      } else {
        throw error.response;
      }
    });
};

const getSearchData = (searchAPI, url, params) => {
  const state = store.getState();
  const options = {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const apiUrl = searchAPI;
  //NOTE response is being passed here.... not response.data
  return axios
    .get(apiUrl, options)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.pathname = "/auth/signout";
      } else {
        throw error.response;
      }
    });
};

const getSuggestions = (url, params) => {
  const state = store.getState();
  const options = {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const apiUrl = getLOCSuggestionsUrl + url;
  //NOTE response is being passed here.... not response.data
  return axios
    .get(apiUrl, options)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.pathname = "/auth/signout";
      } else {
        throw error.response;
      }
    });
};

const postDataSecure = (url, token, data) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const apiUrl = getBaseUrl() + url;
  return axios
    .post(apiUrl, JSON.stringify(data), options)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.pathname = "/auth/signout";
      } else {
        throw error.response;
      }
    });
};

/**
 * Note, it seems like this calls the V1 API endpoints. Nice.
 */
const postDataSecureOldVersion = (url, token, data) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const apiUrl = process.env.REACT_APP_SERVER_URL_BASE + url;
  return axios
    .post(apiUrl, JSON.stringify(data), options)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.pathname = "/auth/signout";
      } else {
        throw error.response;
      }
    });
};

// version 1 with axios. seemed to fail on url encoed
const sendCodeTest = (url, token, data) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .post(url, options)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) {
        console.log("got a 401 :(");
        // window.location.pathname = "/auth/signout";
      } else {
        console.log("got some error");
        throw error.response;
      }
    });
};

// version 2 with fetch
const postCodeTest = async (url, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token,
    },
  };
  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(`Status: ${response.status}, Details: ${errorDetails}`);
    }
    const data = await response.json();
    console.log("Success:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export {
  getDataSecure,
  postDataSecure,
  postDataSecureOldVersion,
  sendCodeTest,
  postCodeTest,
  getSearchData,
  getSuggestions,
};
