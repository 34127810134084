import { useState, useContext, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import Loader from "../../../components/Loader";
import NotyfContext from "../../../contexts/NotyfContext";
import { setStepCount, stopTutor } from "../../../redux/tutor/actions";
import libraryOfCongressLogo from "../../../assets/img/brands/libraryOfCongress.svg";
import nationalArchivesLogo from "../../../assets/img/brands/nationalArchives.png";
import appleMusicLogo from "../../../assets/img/brands/appleMusic.png";
import imdbLogo from "../../../assets/img/brands/imdb.png";
import gettyLogo from "../../../assets/img/brands/Getty_Images_Logo.png";
import gracenoteLogo from "../../../assets/img/brands/Gracenote_logo.png";
import amazonLogo from "../../../assets/img/brands/Amazon_logo.png";
import tmdbLogo from "../../../assets/img/brands/tmdb_logo.png";

const StarterModal = (props) => {
  const customerState = useSelector((state) => state.Customer);
  const [loading, setLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState();
  const tutorState = useSelector((state) => state.Tutor);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const ValidationSchema = Yup.object().shape({
    organizationName: Yup.string().required(
      "Organization name cannot be empty"
    ),
  });

  const handleUpdate = async (value) => {
    console.log("i am here to check organization");
    console.log(value.organizationName);
    let requestData = {};
    requestData["organizationName"] = value.organizationName;
    requestData["organizationId"] = value.organizationId;
    setLoading(true);
  };

  useEffect(() => {
    if (
      customerState.info.organizations &&
      customerState.info.organizations[0]
    ) {
      setOrganizationName(customerState.info.organizations[0]);
    }
  });

  /**
   * Allows users to use the 'Enter' key to submit the change of org name
   */
  const enterKeyListener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      // handleUpdate(values);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        // show={tutorState.isActive && tutorState.tutorStep === 1}
        show={props.show}
        onHide={() => props.toggle()}
        size="lg"
      >
        <Modal.Body>
          <h1>Update your Profile</h1>
          <div className="row">
            <div className="col-6 p-5 start_form_outer_logo">
              {/* <img src={OrganizationLogo} className="w-100" /> */}
            </div>
            <div className="col-6 start_form_outer">
              <div className="p-5"></div>
            </div>
          </div>
          <div>
            <div>
              {tutorState.isActive ? (
                <Button
                  className="mx-2 float-end"
                  onClick={() => {
                    dispatch(setStepCount(3));
                  }}
                >
                  Next
                </Button>
              ) : (
                ""
              )}
              <Button
                className="float-end"
                onClick={() => {
                  if (tutorState.isActive) {
                    dispatch(stopTutor());
                  } else {
                    props.toggle();
                  }
                }}
              >
                Skip
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StarterModal;
