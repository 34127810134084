import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as MagnifyingGlassSVG } from "../../../assets/img/magnifying-glass.svg";
import axios from "axios";

export const SearchContext = React.createContext();
export const SearchAPIContext = React.createContext();

function SearchBar() {
  const { searchQuery, setSearchQuery, searchAPI, setSearchAPI } =
    useContext(SearchContext);
  const [input, setInput] = useState(searchQuery);
  const [suggestions, setSuggestions] = useState([]);

  // Update input when searchQuery changes
  useEffect(() => {
    console.log("search query is " + searchQuery);
    setInput(searchQuery);
  }, [searchQuery]);

  // keep this the same and do not mess with it.
  const fetchSuggestions = () => {
    if (input !== "") {
      const baseURL = "https://www.loc.gov/search/suggest/?q=";
      fetch(baseURL + input)
        .then((response) => response.json())
        .then((data) => {
          const suggestions = data[1]; // This assumes the second element in the array is always the suggestions
          setSuggestions(suggestions);
        })
        .catch((err) => console.error(err)); // Always good to catch any potential errors
    } else {
      setSuggestions([]); // Clear suggestions when input is empty
    }
  };

  const handleSearch = (event) => {
    // If there's an event (like a form submission), prevent page refresh
    if (event) event.preventDefault();
    setSearchQuery(input);
    setSuggestions([]); // Clear suggestions after search
  };

  const handleDropSearch = (query) => {
    // If there's an event (like a form submission), prevent page refresh
    // if (event) event.preventDefault();
    setSearchQuery(query);
    setSuggestions([]); // Clear suggestions after search
  };

  const searchInputStyle = {
    width: "597px",
    height: "40px",
    flexShrink: "0",
    borderRadius: "20px",
    border: "1px solid #1D1D1D",
    paddingLeft: "50px", // Adjust this value according to the width of your magnifying glass icon
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        // Delay form submission
        setTimeout(handleSearch, 0);
      }}
      style={{ position: "relative" }}
    >
      {" "}
      <MagnifyingGlassSVG
        style={{ position: "absolute", top: "8px", left: "10px" }}
      />
      <input
        style={searchInputStyle}
        type="text"
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
          fetchSuggestions();
        }}
        placeholder="Search patches by name"
      />
      <ul
        style={{
          position: "absolute",
          width: "100%",
          backgroundColor: "white",
          zIndex: 1000,
          listStyleType: "none",
          padding: 0,
          margin: 0,
        }}
      >
        {suggestions.map((suggestion, i) => (
          <li
            key={i}
            style={{
              cursor: "pointer",
              padding: "10px",
            }}
            onClick={() => {
              console.log("SUGGESTION ", suggestion);
              console.log("SUGGESTION IIII  ", suggestion[i]);
              setInput(suggestion);
              handleDropSearch(suggestion);
            }}
          >
            {suggestion}
          </li>
        ))}
      </ul>
    </form>
  );
}

export default SearchBar;
