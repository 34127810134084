import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import SendNanoForm from "./SendNanoForm";
import ThesaurusExplorer from "./ThesaurusExplorer";
import { getCustomerInfoSuccess } from "../../../redux/customer/actions";
// import { ConstantKey } from "../../../constants";
import { URL } from "../../../constants/url";
import * as Api from "../../../helper/api";
import { getUserGuid } from "../../../utils/auth0";
import libraryOfCongressLogo from "../../../assets/img/brands/libraryOfCongress.svg";
import nationalArchivesLogo from "../../../assets/img/brands/nationalArchives.png";
import appleMusicLogo from "../../../assets/img/brands/appleMusic.png";
import imdbLogo from "../../../assets/img/brands/imdb.png";
import gettyLogo from "../../../assets/img/brands/Getty_Images_Logo.png";
import gracenoteLogo from "../../../assets/img/brands/Gracenote_logo.png";
import amazonLogo from "../../../assets/img/brands/Amazon_logo.png";
import tmdbLogo from "../../../assets/img/brands/tmdb_logo.png";
import SearchModal from "./SearchModal";
import SourceModal from "./SourceModal";
import SearchBar, { SearchContext } from "./SearchBar";

const ProfileUpdationUI = () => {
  const customerState = useSelector((state) => state.Customer);
  const [count, setCount] = useState(0);
  const [isEmailVerified, setEmailVerifiedFlag] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.Auth);
  const [showSearchModal, setSearchModal] = useState(false);
  const [showSourceModal, setSourceModal] = useState(false);
  const [isLibraryOfCongress, setLibraryOfCongress] = useState(true);
  const [isNationalLibrary, setNationalLibrary] = useState(false);
  const [isItunes, setItunes] = useState(false);
  const [isIMDB, setIMDB] = useState(false);
  const [isGetty, setGetty] = useState(false);
  const [isGracenote, setGracenote] = useState(false);
  const [isAmazon, getAmazon] = useState(false);
  const [isTMDB, getTMDB] = useState(false);
  const [liveSearch, getSearch] = useState(false);
  const { searchQuery } = useContext(SearchContext); // Get searchQuery from context

  /**
   * This SHOULD handle instances where an organization is not yet available for new customers
   */
  useEffect(() => {
    if (customerState.info.emailVerified) {
      setEmailVerifiedFlag(true);
      setCount(count + 1);
      console.log("Email Verified!");
    } else {
      console.log("customerState.info.emailVerified is not valid");
    }
    fetchUserInfo();
  }, [isEmailVerified]);

  /**
   * Counts the "First Reward Given" card
   */
  useEffect(() => {
    if (setLibraryOfCongress) {
      setCount(count + 1);
      console.log("");
    }
  }, [isLibraryOfCongress]);

  /**
   * For counting Sendgrid API key towards completion ratio
   */
  useEffect(() => {
    if (setNationalLibrary) {
      setCount(count + 1);
      console.log("National library selected");
    }
  }, [isNationalLibrary]);

  const toggleSearchModal = () => {
    setSearchModal(!showSearchModal);
  };

  const toggleSourceModal = () => {
    setSourceModal(!showSourceModal);
  };

  /**
   * NEW
   * NOTE: the differences between returning authId (User) and auth0Id (Customer) are HORRIBLE.
   *  Serious delays when copying code from dashboard.fyncom.com to app.fyncom.com as the variable names REALLY screw you up.
   *  Suggestion: Update backend to return "auth0Id", not "authId" for the auth object.
   */
  const fetchUserInfo = async () => {
    try {
      let response = await Api.getDataSecure(
        URL.getUserInfo(
          customerState.info.authId === undefined
            ? getUserGuid(authState.user.sub)
            : customerState.info.authId
        ),
        authState.token
      );
      dispatch(getCustomerInfoSuccess(response.data));
    } catch (error) {
      console.log("found error of " + error);
      // setUserInfo(null);
    }
  };

  return (
    <Row>
      <div className="pb-4">
        {searchQuery === "" ? (
          <div></div>
        ) : (
          <div>
            <Row>
              {/* Verify your email Card*/}
              <Col className="d-flex card-column">
                <Card
                  className={`card-equal-size flex-row ${
                    searchQuery !== "" ? "highlighted" : ""
                  }`}
                >
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        //
                        // e.preventDefault();
                        // fetchOrg();
                      }}
                    >
                      <img src={libraryOfCongressLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* Add Organization card */}
              <Col className="d-flex card-column">
                <Card className="card-equal-size flex-row">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        // e.preventDefault();
                        // toggleSearchModal();
                      }}
                    >
                      <img src={nationalArchivesLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* setup email forwarding card */}
              <Col className="d-flex card-column">
                <Card className="card-equal-size flex-row">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        // e.preventDefault();
                        // handleEmailFwdStatus();
                      }}
                    >
                      <img src={appleMusicLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* first reward offer completed card */}
              <Col className="d-flex card-column">
                <Card className="card-equal-size flex-row">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        // e.preventDefault();
                        // handleCheckFirstRewardOfferCompletedStatus();
                      }}
                    >
                      <img src={imdbLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* IMDB card */}
              <Col className="d-flex card-column">
                <Card className="card-equal-size flex-row">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        // e.preventDefault();
                        // toggleSendgridModal();
                      }}
                    >
                      <img src={gettyLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* setup email forwarding card */}
              <Col className="d-flex card-column">
                <Card className="card-equal-size flex-row">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        // e.preventDefault();
                        // handleEmailFwdStatus();
                      }}
                    >
                      <img src={gracenoteLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* first reward offer completed card */}
              <Col className="d-flex card-column">
                <Card className="card-equal-size flex-row">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        // e.preventDefault();
                        // handleCheckFirstRewardOfferCompletedStatus();
                      }}
                    >
                      <img src={amazonLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* TMDB card */}
              <Col className="d-flex card-column">
                <Card className="card-equal-size flex-row">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={(e) => {
                        // e.preventDefault();
                        // toggleSendgridModal();
                      }}
                    >
                      <img src={tmdbLogo} className="card-image" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <SourceModal
              show={showSourceModal}
              toggle={toggleSourceModal.bind()}
              // updateOrgStatus={updateOrgName.bind()}
            />
            <SearchModal
              show={showSearchModal}
              toggle={toggleSearchModal.bind()}
              // updateApiKeyStatus={updateApiKey.bind()}
            />
          </div>
        )}
      </div>
    </Row>
  );
};

const Default = () => {
  const tutorState = useSelector((state) => state.Tutor);
  const [showSourceModal, setSourceModal] = useState(false);
  const [showSearchModal, setSearchModal] = useState(false);
  const toggleSourceModal = () => {
    setSourceModal(!showSourceModal);
  };
  const toggleSearchModal = () => {
    setSearchModal(!showSearchModal);
  };

  const updateOrgName = (value) => {
    // setOrgFlag(value);
  };
  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <ToastContainer />
      <Container fluid className="p-0">
        {/* <Header /> */}
        <ProfileUpdationUI />
        {/* <Statistics /> */}
        <ThesaurusExplorer />
        {/* <SendNanoForm /> */}
      </Container>
    </React.Fragment>
  );
};

export default Default;
