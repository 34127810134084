export const URL = {
  rewardGcow: "/rewards/gcow",
  getBalance: (id) => {
    return `/nano/user/${id}/balance`;
  },
  updateUser: "/user/update",
  updateUserPrefs: "/preference/update",
  updateCrowdin: "/webhooks/update/service",
  getGiftCardHistory: (auth0Id) => `/rewards/user/${auth0Id}/query`,
  getReferalCode: () => "/nano/getReferral",
  sendNano: "/nano/user/send",
  getUserInfo: (id) => `/user/${id}/info`,
  searchNationalArchives: (query) =>
    `/proxy/records/search?page=1&limit=20&q=${query}&abbreviated=true&debug=true&datesAgg=TRUE`,
  searchLibraryOfCongress: (query) => `/search/?fo=json&q=${query}`,
  suggestFromLibraryOfCongress: (query) => `/search/suggest/?q=${query}`,
  sendCode: (code, verifier) =>
    `/v2/api/auth/google/callback/test/${code}/${verifier}`,
};
