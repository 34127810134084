/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import {
  faSort,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
import PaginationComp from './pagination';


const getHeaderColumn = (column) => {
  if (!column.disableSortBy) {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return (
          <div className="table__header">
            <span>{column.render("Header")}</span>
            {/* <span> */}
            {/* <FontAwesomeIcon icon={faSortDown} /> */}
            {/* </span> */}
          </div>
        );
      }
      return (
        <div className="table__header">
          <span>{column.render("Header")}</span>
          {/* <span> */}
          {/* <FontAwesomeIcon icon={faSortUp} /> */}
          {/* </span> */}
        </div>
      );
    }
    return (
      <div className="table__header">
        <span>{column.render("Header")}</span>
        {/* <span> */}
        {/* <FontAwesomeIcon icon={faSort} /> */}
        {/* </span> */}
      </div>
    );
  }
  return (
    <div>
      <span>{column.render("Header")}</span>
    </div>
  );
};

const DataTable = (props) => {
  const {
    columns,
    data,
    actionButtons,
    sortBy,
    showDateRangeSelector,
    dateRangeSelector,
    showInvoiceFilter = false,
    selectHandler = false,
    filterMenu = [],
    filterLabel = "",
  } = props;

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize, globalFilter },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = instance;

  return (
    <div className="table__wrapper">
      <table {...getTableProps()} className="data-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      className: column.className,
                    })
                  )}
                >
                  {getHeaderColumn(column)}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps({
                      className: cell.column.className,
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div>
        <PaginationComp
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div> */}
    </div>
  );
};

export default DataTable;
