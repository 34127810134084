import { useState, useContext, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { Info } from "react-feather";
import libraryOfCongressLogo from "../../../assets/img/brands/libraryOfCongress.svg";
import NotyfContext from "../../../contexts/NotyfContext";
import { setStepCount, stopTutor } from "../../../redux/tutor/actions";

const SearchModal = (props) => {
  const [apiKey, setApiKey] = useState("");
  const [sendgridObj, setSendgrid] = useState();
  const customerState = useSelector((state) => state.Customer);
  const [loading, setLoading] = useState(false);
  // const [showStarterModal, setStarterModule] = useState(false);
  const tutorState = useSelector((state) => state.Tutor);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  const ValidationSchema = Yup.object().shape({
    apikey: Yup.string(),
  });

  const handleUpdate = async (value) => {
    console.log("i am here to check sendgrid");
    let requestData = {};
    requestData["apiKey"] = value.apiKey;
    requestData["service"] = "SENDGRID";
    setLoading(true);
    console.log(requestData);
    try {
      // await Api.postDataWithoutAuth(
      //   ApiRoutes.addMailProviderKeyRoute(),
      //   requestData
      // );
      // setLoading(false);
      // notyf.open({
      //   type: "success",
      //   message: "API key successfully added. Check your email to see.",
      // });
      props.updateApiKeyStatus(true);
      props.toggle(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      const status = Object.getOwnPropertyDescriptor(error, "status");
      if (error.status === 403) {
        notyf.open({
          type: "error",
          message: "That was not a valid Sendgrid API key. Please try again.",
        });
      } else if (error.status === 401) {
        notyf.open({
          type: "error",
          message: "Your account's Role is not authorized to set API keys.",
        });
      } else {
        //Note, this may have some edge cases that are note handled by backend.
        notyf.open({
          type: "error",
          message: "Please update the permissions of this Sendgrid API Key.",
        });
      }
    }
  };

  /**
   * Allows users to use the 'Enter' key to submit the change of org name
   */
  const enterKeyListener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      // handleUpdate(values);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        // show={tutorState.isActive && tutorState.tutorStep === 1}
        show={props.show}
        onHide={() => props.toggle()}
        size="lg"
      >
        <Modal.Body>
          <h1>Enter your Sendgrid API Key</h1>
          <div className="row">
            <div className="col-6 p-5 start_form_outer_logo">
              <img src={libraryOfCongressLogo} className="w-100" />
            </div>
            <div className="col-6 start_form_outer">
              <div className="p-5">
                <Formik
                  // initialValues={initialCustomerDetails}
                  initialValues={{
                    apiKey,
                  }}
                  validationSchema={ValidationSchema}
                  onSubmit={(values, action) => {
                    handleUpdate(values, action);
                  }}
                >
                  {(formikProps) => {
                    const { values, handleBlur, handleChange, isValid } =
                      formikProps;
                    return (
                      <form
                        className="starter_form"
                        onSubmit={enterKeyListener}
                      >
                        <Form.Group className="mb-3">
                          <div id="SearchModal-Field-Title">
                            <Form.Label className="starter_form_label">
                              Sendgrid API key (optional)
                            </Form.Label>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-help">
                                  You can change or update your Sendgrid API key
                                  clicking the top right corner under settings.
                                </Tooltip>
                              }
                            >
                              <Info size="15" className="recharge-info__icon" />
                            </OverlayTrigger>
                          </div>
                          <Form.Control
                            type="text"
                            as="input"
                            name="apiKey"
                            placeholder="Enter Sendgrid API key"
                            className="starter_form_input"
                            value={values.apiKey}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Form.Group>

                        <div>
                          <div className="d-inline" id="create_btn">
                            <Button
                              type="submit"
                              variant="primary"
                              className="form-btn"
                              disabled={!isValid}
                              onClick={(e) => {
                                console.log(e);
                                handleUpdate(values);
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                        <br></br>
                        <div>
                          <p>
                            For more information about Sendgrid, click&nbsp;
                            <a href="https://www.fyncom.com/sendgrid-integration-api-key">
                              here
                            </a>
                            .
                          </p>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SearchModal;
