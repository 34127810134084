import React, { useEffect, useRef, useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import useSidebar from "../../hooks/useSidebar";
import NavbarUser from "./NavbarUser";
import SearchBar, {
  SearchContext,
} from "../../pages/dashboards/Default/SearchBar";
import { useLocation } from "react-router-dom";

const NavbarComponent = () => {
  const location = useLocation();
  const { isOpen, setIsOpen } = useSidebar();
  const { searchQuery, setSearchQuery } = useContext(SearchContext);

  let navText = "Viewer";

  // Set the text based on the current path
  if (location.pathname === "/dashboard") {
    navText = "Viewer";
  } else if (location.pathname === "/settings") {
    navText = "Patches";
  } else if (location.pathname === "/wallet") {
    navText = "Brands";
  }

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span className="navbar-text-left">{navText}</span>
      <div
        style={{
          display: "flex",
          // flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex=start",
          gap: "10px",
          width: "100%",
        }}
      >
        <SearchBar />
      </div>

      <Nav className="navbar-align">
        <NavbarUser />
      </Nav>
    </Navbar>
  );
};

export default NavbarComponent;
