import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/lib/integration/react";
import Auth0ProviderWithNavigate from "./auth/auth0-provider-with-navigate";
import Loader from "./components/Loader";
import { LayoutProvider } from "./contexts/LayoutContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import "./i18n";
import { persistor, store } from "./redux/store";
import routes from "./routes";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import SearchBar, {
  SearchContext,
} from "../src/pages/dashboards/Default/SearchBar"; // or wherever the path to SearchBar is

const App = () => {
  const content = useRoutes(routes);
  const isProduction = process.env.NODE_ENV === "production";
  const titleTemplate = isProduction ? "Kwillt" : "Kwillt Local App";
  const defaultTitle = isProduction ? "Kwillt" : "Kwillt Local App";
  const [searchQuery, setSearchQuery] = useState("");
  // const [searchAPI, setSearchAPI] = useState(
  // "https://www.loc.gov/search/?fo=json&q="
  // );
  const [searchAPI, setSearchAPI] = React.useState(
    localStorage.getItem("searchAPI") ||
      process.env.REACT_APP_LIBRARY_OF_CONGRESS_SEARCH_BASE_URL
  );

  const handleSearch = (query) => {
    // This function will be passed to the SearchBar
    setSearchQuery(query);
  };

  return (
    <HelmetProvider>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <ThemeProvider>
            <SidebarProvider>
              <LayoutProvider>
                <SearchContext.Provider
                  value={{
                    searchQuery,
                    setSearchQuery,
                    searchAPI,
                    setSearchAPI,
                  }}
                >
                  {/* <SearchBar /> */}
                  <ChartJsDefaults />
                  <Auth0ProviderWithNavigate>
                    {content}
                  </Auth0ProviderWithNavigate>
                </SearchContext.Provider>
              </LayoutProvider>
            </SidebarProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
