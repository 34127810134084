import React, { useEffect, useState, useContext } from "react";
import DataTable from "../../components/datatable/index";
import { Card, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import { DateCellRenderer } from "../../utils/datatable";
import SearchBar, {
  SearchContext,
} from "../../pages/dashboards/Default/SearchBar";
import libraryOfCongressLogo from "../../assets/img/brands/libraryOfCongress.svg";
import nationalArchivesLogo from "../../assets/img/brands/nationalArchives.png";
import appleMusicLogo from "../../assets/img/brands/appleMusic.png";
import imdbLogo from "../../assets/img/brands/imdb.png";
import gettyLogo from "../../assets/img/brands/Getty_Images_Logo.png";
import gracenoteLogo from "../../assets/img/brands/Gracenote_logo.png";
import amazonLogo from "../../assets/img/brands/Amazon_logo.png";
import tmdbLogo from "../../assets/img/brands/tmdb_logo.png";

function CampaignsTransaction() {
  const [transactions, setTransactions] = useState();
  const customerState = useSelector((state) => state.Customer);
  const notyf = useContext(NotyfContext);
  const { searchQuery, searchAPI, setSearchAPI } = useContext(SearchContext);

  const createNALink = (objectType, query) => {
    return `https://catalog.archives.gov/search?objectType=${encodeURIComponent(
      objectType
    )}&q=${encodeURIComponent(query)}`;
  };

  const LinkCell = ({ cell: { value }, column: { id }, row: { original } }) => {
    if (
      searchAPI === process.env.REACT_APP_LIBRARY_OF_CONGRESS_SEARCH_BASE_URL
    ) {
    const link = original[id + "Link"].replace("&fo=json", "");
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {value.toLocaleString()}
        </a>
      );
    } else {

      // national arvhives
      let link = "test";
    switch (id) {
      case "image":
        link = original.imageLink;
        value = original.image.toLocaleString();
        break;
      case "pdf":
        link = original.pdfLink;
        value = original.pdf.toLocaleString();
        break;
      case "video":
        link = original.videoLink;
        value = original.video.toLocaleString();
        break;
    }
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    }
  };

  const fetchLocData = (searchQuery) => {
    if (searchQuery !== "") {
      const baseURL = "https://www.loc.gov/search/?fo=json&q=";
      fetch(baseURL + searchQuery)
        .then((response) => response.json())
        .then((data) => {
          const name = data.facet_trail[0].value;
          const onlineFormatFacet = data.facets.find(
            (facet) => facet.title === "Online Format"
          );

          if (onlineFormatFacet) {
            let preparedData = {
              name: name,
              source: "Library of Congress",
              status: "Active", // This is a boolean property
            };
            onlineFormatFacet.filters.forEach((filter) => {
              console.log("bucker PDSFKLASJDLKDSJASDfond", filter);
              console.log("filter ti tl ", filter.title)

              preparedData[filter.title] = filter.count;
              preparedData[filter.title + "Link"] = filter.on;
            });
            console.log("all prep data LOC, ", preparedData);

            setTransactions([preparedData]);
          }
        })
        .catch((error) => {
          notyf.open({
            type: "error",
            message: "There was an error fetching data: " + error.message,
          });
        });
    }
  };

  // uses URI concoded search query
  const fetchNaData = (searchQuery) => {
    if (searchQuery !== "") {
      fetch(
        "https://catalog.archives.gov/proxy/records/search?page=1&limit=20&q=" +
          encodeURIComponent(searchQuery) +
          "&abbreviated=true&debug=true&datesAgg=TRUE"
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("all data is ", data);
          const name = searchQuery;
          const buckets = data.body.aggregations.objectType.buckets;

          if (buckets) {
            let preparedData = {
              name: name,
              source: "National Archives",
              status: "Active",
            };
            buckets.forEach((bucket) => {
              // console
              switch (bucket.key) {
                case "Image (JPG)":
                  preparedData["image"] = bucket.doc_count;
                  preparedData["imageLink"] = createNALink("jpg", name);
                  break;
                case "Portable Document File (PDF)":
                  preparedData["pdf"] = bucket.doc_count;
                  preparedData["pdfLink"] = createNALink("pdf", name);
                  break;
                case "Audio/Visual File (MP4)":
                  preparedData["video"] = bucket.doc_count;
                  preparedData['videoLink'] = createNALink("mp4", name);
                  break;
                default:
                  // Ignore other types
                  break;
              }
            });
            console.log("all prep data, ", preparedData);
            setTransactions([preparedData]);
          }
        })
        .catch((error) => {
          notyf.open({
            type: "error",
            message: "There was an error fetching data: " + error.message,
          });
        });
    }
  };

  useEffect(() => {
    if (
      searchAPI === process.env.REACT_APP_LIBRARY_OF_CONGRESS_SEARCH_BASE_URL
    ) {
      fetchLocData(searchQuery);
    } else {
      fetchNaData(searchQuery);
    }
  }, [searchQuery]);

  const initialSort = [
    {
      id: "nanoAmount",
      desc: true,
    },
  ];
const columns = React.useMemo(() => {
  if (searchAPI === process.env.REACT_APP_LIBRARY_OF_CONGRESS_SEARCH_BASE_URL) {
    console.log(" DIS SERACH OR NAH?")
    return [
      {
        Header: "NAME",
        accessor: "name",
      },
      {
        Header: "SOURCE",
        accessor: "source",
      },
      {
        Header: "PHOTOS",
        accessor: "image",
        Cell: LinkCell,
      },
      {
        Header: "DOCS",
        accessor: "pdf",
        Cell: LinkCell,
      },
      {
        Header: "FILM",
        accessor: "video",
        Cell: LinkCell,
      },
      {
        Header: "STATUS",
        accessor: "status",
      },
    ]
  } else {
    console.log(" DIS NATTY OR NAH?")

    return [
      {
        Header: "NAME",
        accessor: "name",
      },
      {
        Header: "SOURCE",
        accessor: "source",
      },
      {
        Header: "PHOTOS",
        accessor: "image",
        Cell: LinkCell,
      },
      {
        Header: "DOCS",
        accessor: "pdf",
        Cell: LinkCell,
      },
      {
        Header: "FILM",
        accessor: "video",
        Cell: LinkCell,
      },
      {
        Header: "STATUS",
        accessor: "status",
      },
    ]
  }
})

  return (
    <div>
      <Row>
        {/* Library of congress API Card*/}
        <Col
          className="d-flex card-column"
          onClick={(e) => {
            const newAPI =
              process.env.REACT_APP_LIBRARY_OF_CONGRESS_SEARCH_BASE_URL;
            localStorage.setItem("searchAPI", newAPI);
            setSearchAPI(newAPI);
          }}
        >
          <Card
            className={`card-equal-size flex-row ${
              searchAPI ===
              process.env.REACT_APP_LIBRARY_OF_CONGRESS_SEARCH_BASE_URL
                ? "highlighted"
                : ""
            }`}
          >
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div>
                <img src={libraryOfCongressLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* National Archives card */}
        <Col
          className="d-flex card-column"
          onClick={(e) => {
            const newAPI =
              process.env.REACT_APP_NATIONAL_ARCHIVE_SEARCH_BASE_URL;
            localStorage.setItem("searchAPI", newAPI);
            setSearchAPI(newAPI);
          }}
        >
          <Card
            className={`card-equal-size flex-row ${
              searchAPI ===
              process.env.REACT_APP_NATIONAL_ARCHIVE_SEARCH_BASE_URL
                ? "highlighted"
                : ""
            }`}
          >
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div>
                <img src={nationalArchivesLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* setup email forwarding card */}
        <Col className="d-flex card-column">
          <Card className="card-equal-size flex-row">
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div
                onClick={(e) => {
                  // e.preventDefault();
                  // handleEmailFwdStatus();
                }}
              >
                <img src={appleMusicLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* first reward offer completed card */}
        <Col className="d-flex card-column">
          <Card className="card-equal-size flex-row">
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div
                onClick={(e) => {
                  // e.preventDefault();
                  // handleCheckFirstRewardOfferCompletedStatus();
                }}
              >
                <img src={imdbLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* IMDB card */}
        <Col className="d-flex card-column">
          <Card className="card-equal-size flex-row">
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div
                onClick={(e) => {
                  // e.preventDefault();
                  // toggleSendgridModal();
                }}
              >
                <img src={gettyLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* setup email forwarding card */}
        <Col className="d-flex card-column">
          <Card className="card-equal-size flex-row">
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div
                onClick={(e) => {
                  // e.preventDefault();
                  // handleEmailFwdStatus();
                }}
              >
                <img src={gracenoteLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* first reward offer completed card */}
        <Col className="d-flex card-column">
          <Card className="card-equal-size flex-row">
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div
                onClick={(e) => {
                  // e.preventDefault();
                  // handleCheckFirstRewardOfferCompletedStatus();
                }}
              >
                <img src={amazonLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* TMDB card */}
        <Col className="d-flex card-column">
          <Card className="card-equal-size flex-row">
            <Card.Body className="d-flex align-items-center justify-content-center">
              <div
                onClick={(e) => {
                  // e.preventDefault();
                  // toggleSendgridModal();
                }}
              >
                <img src={tmdbLogo} className="card-image" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Card> */}
      {/* <Card.Body> */}
      <DataTable
        columns={columns}
        data={transactions ? transactions : []}
        sortBy={initialSort}
      />
      {/* </Card.Body> */}
      {/* </Card> */}
    </div>
  );
}

// This is a function that formats a number with commas.
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// This is your custom table cell component.
function NumberCell({ value }) {
  return numberWithCommas(value || 0);
}

export default CampaignsTransaction;
