import React from "react";
// Guards
import AuthGuard from "./components/guards/AuthGuard";
// import Settings from "./components/settings/index";
// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import SignIn from "./pages/auth/SignIn";
import SignInSuccess from "./pages/auth/signInSuccess";
import Signout from "./pages/auth/Signout";
// Dashboards
import Default from "./pages/dashboards/Default";
import SendNanoForm from "./pages/dashboards/Default/SendNanoForm";
//GiftCard
import Landing from "./pages/landing/Landing";
//Settings
import Settings from "./pages/settings/index";

const routes = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "dashboard",
    element: (
      // <AuthGuard>
      <DashboardLayout />
      // </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: (
          <div>
            <Default />
          </div>
        ),
      },
    ],
  },
  {
    path: "wallet",
    element: (
      // <AuthGuard>
      <DashboardLayout />
      // </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SendNanoForm />,
      },
    ],
  },
  {
    path: "settings",
    element: (
      // <AuthGuard pathname="/settings">
      <DashboardLayout />
      // </AuthGuard>
    ),
    children: [
      {
        path: "/settings",
        element: (
          // <AuthGuard>
          <Settings />
          // </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in/success",
        element: <SignInSuccess />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "signout",
        element: <Signout />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
