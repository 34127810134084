import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Settings } from "react-feather";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const NavbarUser = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const userPrefState = useSelector((state) => state.Auth.userPref); //setting this up for later

  const handleLogout = () => {
    navigate("/auth/signout");
  };

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <span className="text-dark">{user && user.nickname}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        {/* <Dropdown.Item>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Divider /> */}
        <Dropdown.Item onClick={() => navigate("/settings")}>
          Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
