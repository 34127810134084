import { ReactComponent as ViewerIconSVG } from "../../assets/img/icon-left-react.svg";
import { ReactComponent as SquareSVG } from "../../assets/img/squre-icon-react.svg";
import { ReactComponent as BriefcaseSVG } from "../../assets/img/briefcase-react-custom.svg";
import { useDispatch } from "react-redux";

const ViewerIcon = () => <ViewerIconSVG />;
const SquareIcon = () => <SquareSVG />;
const BriefcaseIcon = () => <BriefcaseSVG />;

const pagesSection = [
  {
    href: "/dashboard",
    icon: ViewerIcon,
    title: "View",
  },
  {
    href: "/settings",
    icon: SquareIcon,
    title: "Patches",
  },
  // {
  //   href: "/wallet",
  //   icon: BriefcaseIcon,
  //   title: "Brands",
  // },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
