import React, { useEffect, useRef, useContext } from "react";
import * as d3 from "d3";
import SearchBar, {
  SearchContext,
} from './SearchBar';

function Graph({ rootNode, onNodeClick }) {
  const svgRef = useRef();
  const gRef = useRef(); // Reference to the group element
  const width = 1080;
  const height = 1080;

  const { searchQuery } = useContext(SearchContext); // Get searchQuery from context

  useEffect(() => {
    const svgBounds = svgRef.current.getBoundingClientRect();

    if (searchQuery === '') {
      // Render your message for empty keyword
      const svg = d3.select(svgRef.current);
      const g = d3.select(gRef.current);
      g.selectAll("*").remove(); // Clear previous data
      g.append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        // .style("font-size", "24px")
        .style("font-family", "georgia")
        .call(createTextLines, "What are you Searching for?|Type your search on top to get started", ['24px', '16px'])
        .attr("transform", `translate(${width / 2}, -${height / 4})`);
    } else {
      const svg = d3.select(svgRef.current);
      const g = d3.select(gRef.current); // Select the group element
      const treeLayout = d3.tree().size([width, height]);

      const root = d3.hierarchy(rootNode); // Convert the data to a hierarchy
      treeLayout(root); // Compute the tree layout
      const simulation = d3 // Define the forces for the simulation
        .forceSimulation(root.descendants())
        .force("link", d3.forceLink().id((d) => d.data.title).links(root.links()).strength(0.2))
        .force("charge", d3.forceManyBody().strength(-2000))
        .force("collide", d3.forceCollide().radius(10))
        .force("center", d3.forceCenter(svgBounds.width / 2, svgBounds.height / 2))
        .on("tick", onTick);
      const zoom = d3.zoom() // Define the zoom behavior
        .scaleExtent([0.1, 100]) // control zoom
        .on('zoom', (event) => {
          g.attr('transform', event.transform);
        });
      svg.call(zoom); // Add the zoom behavior to the svg
      const link = g // Render the links
        .selectAll("line")
        .data(root.links())
        .join("line")
        .attr("stroke-width", 2)
        .attr("stroke", "black");
      const node = g // Render the nodes
        .selectAll("circle")
        .data(root.descendants())
        .join("circle")
        .attr("r", 10)
        .attr("fill", "steelblue")
        .style("cursor", "pointer")
        .on("click", (event, d) => {
          console.log("d has this stuff", d);
          const onUrl = d.data.filters[0]?.on;
          console.log("onUrl is ", onUrl);
          if (onUrl) {
            onNodeClick(onUrl, d.data);
          }
        });
      const label = g // Render the labels
        .selectAll("text")
        .data(root.descendants())
        .join("text")
        .attr("text-anchor", "middle")
        .attr("dy", -20)
        .style("font-size", "18px")
        .text((d) => `${d.data.title} (${d.data.count.toLocaleString()})`);
      function onTick() { // Update positions of elements on each tick of simulation
        node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
        link
          .attr("x1", (d) => d.source.x)
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)
          .attr("y2", (d) => d.target.y);
        label.attr("x", (d) => d.x).attr("y", (d) => d.y);
      }
      return () => { // Clean up
        simulation.stop();
        g.selectAll("*").remove();
      };
    }
  }, [searchQuery, rootNode]);

  return (
    <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
      <g ref={gRef} /> 
    </svg>
  );
}

function createTextLines(selection, text, fontSizes) {
  const lines = text.split("|");
  selection.selectAll("tspan")
    .data(lines)
    .enter()
    .append("tspan")
    .attr("x", 0)
    .attr("dy", (d, i) => i ? fontSizes[i - 1] : 0)
    .text((d) => d);
}

export default Graph;
